<template>
  <component :is="event === {} ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="event === {} && !loading"
    >
      <h4 class="alert-heading">
        Erro ao recuperar os dados
      </h4>
      <div class="alert-body">
        Não encontramos os dados desta aula. Voltar para
        <b-link
          class="alert-link"
          :to="{ name: 'courses'}"
        >
          a lista das aulas.
        </b-link>
      </div>
    </b-alert>

    <!-- User Info: Input Fields -->
    <b-form
      v-if="$store.state.quales.event && !loading"
    >
      <b-row class="mb-1">
        <b-col>
          <h4>Aula de {{ event.modality_name }} : {{ dateFormater.format(new Date(event.date_time)) }}</h4>
        </b-col>
        <b-col>
          <b-button
            v-if="new Date(event.date_time) <= tomorrow || userRole === 'ADMIN'"
            class="float-right"
            variant="success"
            @click="save"
          >
            Gravar
          </b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
        >
          <label for="description">Observações</label>
          <b-form-textarea
            id="description"
            v-model="$store.state.quales.event.observations"
            placeholder="Descrição"
            rows="3"
            max-rows="8"
            no-auto-shrink
          />
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col md="6">
          <label
            for="cancelation"
          >Foi cancelado ?</label>
          <v-select
            id="cancelation"
            v-model="$store.state.quales.event.canceled"
            label="text"
            :options="cancellation"
            :reduce="o => o.value"
            placeholder="A aula aconteceu normalmente"
          >
            <template #no-options="{}">
              A lista de opções está vazia
            </template>
          </v-select>
        </b-col>
      </b-row>

      <b-row class="mt-1 mb-1">
        <b-col cols="12">
          <h3>Chamada</h3>

        </b-col>
        <b-col cols="12">
          <label
            for="alunos"
          >Alunos</label>
          <v-select
            id="alunos"
            v-model="newStudent"
            :options="$store.state.students.clients"
            :filterable="false"
            label="full_name"
            @input="alunoPresente"
            @search="onSearch"
          >
            <template #no-options="{}">
              Digite o nome do aluno a encontrar
            </template>
          </v-select>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            v-slot="{ ariaDescribedby }"
            label="Alunos :"
          >
            <b-form-checkbox-group
              id="checkbox-group-2"
              v-model="students"
              :options="registered"
              :aria-describedby="ariaDescribedby"
              value-field="id"
              text-field="full_name"
              stacked
              name="students"
              switches
            />
          </b-form-group>
        </b-col>
      </b-row>

      <hr>

      <b-row>
        <b-col>
          <div class="d-flex mb-1">
            <b-form-checkbox
              v-model="$store.state.quales.event.validated"
              name="validated"
              switch
            />
            <p
              class="mb-0"
            >
              Chamada validada
            </p>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-card-actions
            action-collapse
            title="Tirar alunos da chamada"
            :collapsed="true"
            class="text-left"
          >
            <b-row
              v-for="student in registered"
              :key="student.id"
            >
              <b-col>
                <b-button
                  variant="danger"
                  size="sm"
                  class="ml-1 btn-icon py-0"
                  @click="removeStudentFromRegistered(student.id)"
                >
                  <feather-icon
                    icon="XIcon"
                    class="my-05"
                  />
                </b-button>
                {{ student.full_name }}
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>
      </b-row>

    </b-form>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BFormGroup, BFormInput, BMedia, BButton,
  BRow, BCol, BForm, BAvatar, BFormTextarea, BFormCheckbox, BFormCheckboxGroup,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import vSelect from 'vue-select'
import { getUserData } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCardActions,
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BFormGroup,
    BFormInput,
    BMedia,
    BButton,
    BRow,
    BCol,
    BForm,
    BAvatar,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,

    vSelect,
  },
  props: {
    eventId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      newStudent: null,
      clientData: null,
      loading: true,
      cancellation: [
        {
          value: 'CL',
          text: 'Clima',
        },
        {
          value: 'CA',
          text: 'Cancelado pela administração',
        },
        {
          value: 'FR',
          text: 'Feriado',
        },
        {
          value: 'FP',
          text: 'Falta professor',
        },
        {
          value: 'MN',
          text: 'Manutenção do espaço',
        },
      ],
      students: [
      ],
      registered: [],
    }
  },
  computed: {
    event() {
      return this.$store.state.quales.event
    },
  },
  created() {
    this.userRole = getUserData().role
    this.tomorrow = new Date()
    this.tomorrow.setDate(this.tomorrow.getDate() + 1)
    this.dateFormater = new Intl.DateTimeFormat('pt-br', {
      hour12: false,
      day: 'numeric',
      month: 'long',
      hour: 'numeric',
      minute: '2-digit',
      weekday: 'long',
      timeZone: 'America/Sao_Paulo',
    })
    this.$store.state.quales.event = {}
    this.$store.dispatch('quales/getEventById', this.eventId).then(response => {
      this.$store.state.quales.students = []
      this.registered = response.data.students_registered
      // this.$store.dispatch('quales/getStudents', { courseId: response.data.course })
      this.$store.commit('students/courseFilter', response.data.course)
      this.students = response.data.students_present.map(item => item.id)
      this.loading = false
    })
      .catch(() => {
      })
  },
  methods: {
    alunoPresente() {
      const index = this.registered.map(o => o.id).indexOf(this.newStudent.id)
      if (index !== -1) {
        this.registered.splice(index, 1)
      }
      this.registered.push(this.newStudent)
      this.registered = this.registered.sort((a, b) => a.full_name > b.full_name)
      this.students = this.students.filter((item, i) => this.students.indexOf(item) === i).filter(item => item !== this.newStudent.id)
      this.students.push(this.newStudent.id)
      this.newStudent = null
    },
    removeStudentFromRegistered(studentId) {
      this.registered = this.registered.filter(item => item.id !== studentId)
      this.students = this.students.filter(item => item !== studentId)
      this.$store.commit('quales/removeFromRegisteredStudents', studentId)
    },
    save() {
      this.$store.commit('quales/addStudentsToEvent', this.students)
      this.$store.dispatch('quales/updateEvent', this.$store.state.quales.event).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Aula',
            icon: 'UserCheckIcon',
            variant: 'success',
            text: 'Aula validada',
          },
        })
        this.$router.push({ name: 'history' })
      })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Aula',
              icon: 'UserCheckIcon',
              variant: 'danger',
              text: 'Não foi possível validar essa aula (Para realizar uma chamada pedir para um professor e para cancelar uma chamada para um administrador)',
            },
          })
        })
    },

    onSearch(search, loading) {
      if (search.length) {
        loading(true)
        this.$store.commit('students/searchFilter', search)
        this.$store.dispatch('students/getClients').then(() => {
          loading(false)
        })
          .catch(() => {
            loading(false)
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.delete-button{
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
}
</style>
